<template>
  <ui-drawer type="dismissible" viewport-height nav-id="demo-menu">
    <ui-drawer-header>
      <ui-drawer-title>Настройки</ui-drawer-title>
      <ui-drawer-subtitle>Настройки коэффициентов</ui-drawer-subtitle>
    </ui-drawer-header>
    <ui-drawer-content>
      <ui-nav>
        <ui-nav-item data-tooltip-id="tooltip-title">
          <ui-form-field :class="['search-field', 'required']">
            <label>Название</label>
            <ui-textfield
              v-model="coef.coefficients.title"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-applicability">
          <ui-form-field :class="['search-field', 'required']">
            <label>Применяемость</label>
            <ui-textfield
              v-model="coef.coefficients.applicability"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-brand-title">
          <ui-form-field :class="['search-field', 'required']">
            <label>Брэнд</label>
            <ui-textfield
              v-model="coef.coefficients.brand"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-field-mutch">
          <ui-form-field :class="['search-field', 'required']">
            <label>Процент совпадения</label>
            <ui-textfield
              v-model="coef.coefficients.crossFieldMatch"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-brand-rating">
          <ui-form-field :class="['search-field', 'required']">
            <label>Вес рейтига бренда</label>
            <ui-textfield
              v-model="coef.coefficients.brandRatingWeight"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-original" >
          <ui-form-field :class="['search-field', 'required']">
            <label>Оригинал</label>
            <ui-textfield
              v-model="coef.coefficients.original"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-hit">
          <ui-form-field :class="['search-field', 'required']">
            <label>Хит</label>
            <ui-textfield
              v-model="coef.coefficients.hit"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-promotion">
          <ui-form-field :class="['search-field', 'required']">
            <label>Акция</label>
            <ui-textfield
              v-model="coef.coefficients.promotion"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-minimum-score">
          <ui-form-field :class="['search-field', 'required']">
            <label>Минимальная скора</label>
            <ui-textfield
              v-model="coef.coefficients.minimumScore"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-fuzzines">
          <ui-form-field :class="['search-field', 'required']">
            <label>Fuzziness</label>
            <ui-textfield
              v-model="coef.features.fuzzines"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-rescore-weight">
          <ui-form-field :class="['search-field', 'required']">
            <label>Коэффициент пересчета</label>
            <ui-textfield
              v-model="coef.features.rescoreSettings.rescoreWaight"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item data-tooltip-id="tooltip-rescore-initial-weight">
          <ui-form-field :class="['search-field', 'required']">
            <label>Коэфф нач. результата</label>
            <ui-textfield
              v-model="coef.features.rescoreSettings.initialQueryWaight"
              class="search-field"
            ></ui-textfield>
          </ui-form-field>
        </ui-nav-item>
        <ui-nav-item class="checker" data-tooltip-id="tooltip-inverted">
          <ui-switch
            v-model="coef.features.inverted"
            class="checker-switch"
            input-id="inverted-switch"
            :true-value="true"
            :false-value="false"
          >
            {{ coef.features.inverted ? 'inverted' : 'notinverted' }}
          </ui-switch>
          <label for="inverted-switch">{{ coef.features.inverted ? 'inverted' : 'notinverted' }}</label>
        </ui-nav-item>
        <ui-nav-item class="checker" data-tooltip-id="tooltip-rescore">
          <ui-switch
            v-model="coef.features.rescoreBrandRating"
            class="checker-switch"
            input-id="inverted-switch"
            :true-value="true"
            :false-value="false"
          >
            {{ coef.features.rescoreBrandRating ? 'пересчет по бренду' : 'notinverted' }}
          </ui-switch>
          <label for="inverted-switch">пересчет по бренду</label>
        </ui-nav-item>
        <ui-nav-item class="save-button">
          <ui-button raised @click="saveSettings">Сохранить</ui-button>
        </ui-nav-item>
      </ui-nav>
    </ui-drawer-content>
  </ui-drawer>
  <!-- Content -->
  <ui-drawer-app-content class="demo-content">
    <ui-snackbar
      v-model="snackbar"
      :timeout-ms="1500"
      :message="'настройки сохранены'"
      :action-button-text="'закрыть'"
      :action-type="actionType ? 1 : 0"
    ></ui-snackbar>
    <!-- App bar -->
    <ui-top-app-bar
      class="demo-app-bar"
      content-selector=".about"
      nav-id="demo-menu"
    >
      Настройки
    </ui-top-app-bar>
      <div id="nav">
        <router-link to="/search">Поиск</router-link> |
        <router-link to="/prompt">Подсказки</router-link> |
        <router-link to="/article">Поиск по id</router-link>
      </div>
    <!-- App content -->
      <router-view/>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-title" rich class="tooltip-big">
          <template #title>Вес поля title (название)</template>
          <template #default>
            Множетель оценки релевантности по полю title карточки товара
            <br/>
            Чем выше данное поле по отношению других множетелей полей,
            тем выше будет находиться товар с совпадение запроса в этом поле
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-applicability" rich class="tooltip-big">
          <template #title>Вес поля applicability (применяемость)</template>
          <template #default>
            Множетель оценки релевантности в поле applicability карточки товара
            <br/>
            Чем выше данное поле по отношению других множетелей полей,
            тем выше будет находиться товар с совпадение запроса в этом поле
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-brand-title" rich class="tooltip-big">
          <template #title>Вес поля brand.title (название бренда)</template>
          <template #default>
            Множетель оценки релевантности в поле brand.title карточки товара
            <br/>
            Чем выше данное поле по отношению других множетелей полей,
            тем выше будет находиться товар с совпадение запроса в этом поле
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-field-mutch" rich class="tooltip-big">
          <template #title>Минимальный процент поиска по 3 полям (название, название бренда, применяемость)</template>
          <template #default>
            Указывает какой количество в каком количестве полей минимально должен присутствовать поисковый запрос,
            для того что бы товар попал в результат поиска
            <br/>
            ПРИМЕР: если значение равно 0 то в результат попадают товары даже если искомы запрос не найден ни в одном поле
            <br/>
            если значение равно 100 то в результат попадают только те товары поисковая строка в которых
            частично или полностьб присутсвует в всех полях
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-brand-rating" rich>
          <template #title>Множитель рейтинга бренда</template>
          <template #default>
            Множетель рейтинга бренда для изменения ранжирования найденных товаров
            чем выше данные множетель, тем выше будет товар с самым высоким рейтингом бренда
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-original" rich>
          <template #title>Множитель пиризнака оригинальности товара</template>
          <template #default>
            Чем выше данные множетель, тем выше будет товар с признаком оригинальности
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-hit" rich>
          <template #title>Множитель пиризнака хит товара</template>
          <template #default>
            Чем выше данные множетель, тем выше будет товар с признаком хита
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-promotion" rich>
          <template #title>Множитель пиризнака акция товара</template>
          <template #default>
            Чем выше данные множетель, тем выше будет товар с признаком акция
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-minimum-score" rich class="tooltip-big">
          <template #title>Минимальное числовое значение релевантности</template>
          <template #default>
            Минимальное значение релевантности определеяет пороговое значение результирующего веса товаров для отображения
            <br />
            ПРИМЕР: если значение равно 0 то даже если нет малейшего совпадения по любому полю, в результат попадут каки-то товары
            <br />
            Чем выше данные парраметр тем меньше документов будет в результате поиска и только самые релевантные попадут в результат
            <br />
            ВНИМАНИЕ при сильно большом значение данного поля >20 есть вероятность что при каких-то запросах результат будет пустой
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-fuzzines" rich>
          <template #title>Максимально редакционное расстояние Левенштейна</template>
          <template #default>
            Определяет какое максимальное количество изменений в слове (удаление символа, добавление, перестановка, изменение символа) может быть произведено
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-rescore-weight" rich class="tooltip-big">
          <template #title>Множитель веса пересчета результатов с глубоким учетом названия бренда</template>
          <template #default>
            Множетель пересчета результатов по полю бренда товаров.
            <br />
            ПРИМЕР: Если в поисковом запросе будет присутствовать название какого-либо бренда то пересчет будет поднимать тоавры с этим брендом выше остальных
          </template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-rescore-initial-weight" rich>
          <template #title>Множитель изначального поискового результата для пересчета по полю бренд</template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-inverted" rich>
          <template #title>Использовать ивертирование раскладки</template>
        </ui-tooltip>
      </ui-tooltip-anchor>
      <ui-tooltip-anchor>
        <ui-tooltip id="tooltip-rescore" rich>
          <template #title>Осуществлять пересчет результатов по полю бренд</template>
        </ui-tooltip>
      </ui-tooltip-anchor>
  </ui-drawer-app-content>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      baseUrl: 'https://master-lada-abom-es-api.zteam.dev-kodix.ru',
      snackbar: false,
      coef: {
        coefficients: {
          title: 1,
          applicability: 1,
          brand: 1,
          crossFieldMatch: 50,
          brandRatingWeight: 1.4,
          original: 0.00015,
          hit: 0.0001,
          promotion: 0.00005,
          minimumScore: 10
        },
        features: {
          inverted: true,
          rescoreBrandRating: true,
          fuzzines: 1,
          rescoreSettings: {
            rescoreWaight: 1.4,
            initialQueryWaight: 0.7
          }
        }
      }
    }
  },
  async mounted () {
    const result = await this.$api.getSettings(this.baseUrl)

    this.coef.coefficients = result.coefficients
    this.coef.features = result.features
  },
  methods: {
    async saveSettings () {
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await this.$api.setSettings(this.baseUrl, {
          coefficients: this.coef.coefficients,
          features: this.coef.features
        })
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.save-button {
  margin-top: 20px!important;
  margin-bottom: 20px!important;
  align-items: center!important;
  justify-content: center!important;
}

.checker {
  align-items: center!important;
  justify-content: start!important;
  padding-left: 20px!important;
  &-switch {
    margin-right: 10px!important;
  }
}

.tooltip-big {
  .mdc-tooltip__surface {
    max-height: 600px!important;
  }
}

#nav {
  padding: 30px;
  padding-top: 100px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
