import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ApiService from 'api'

import BalmUI from 'balm-ui' // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus' // BalmJS Team Material Components
import 'balm-ui-css'

createApp(App).use(router).use(BalmUI).use(BalmUIPlus).use(ApiService).mount('#app')
