const axios = require('axios')

const BASE_API_URL = process.env.REACT_APP_BASE_URL || 'https://master-lada-abom-es-api.zteam.dev-kodix.ru'

const ApiService = (axiosInstance) => ({
  getPrompt: async function getPrompt ({ q, baseUrl }) {
    const url = baseUrl || ''
    const response = await axiosInstance.get(url + '/prompt', {
      params: {
        q
      }
    })

    return response.data
  },

  search: async function search (payload) {
    const {
      q,
      user,
      baseUrl,
      sourceIds,
      zoning,
      salable,
      size,
      from,
      withZone3,
      searchByArticles
    } = payload

    const url = baseUrl || ''

    const params = {
      q,
      user,
      withZoning: zoning,
      withSalable: salable,
      size,
      from,
      withZone3,
      searchByArticles
    }

    if (sourceIds) params.sourceIds = sourceIds

    const response = await axiosInstance.get(url + '/console/search', {
      params
    })

    return response.data
  },

  findById: async function findById ({ standardId, baseUrl }) {
    const url = baseUrl || ''

    const params = {
      standardId
    }

    const response = await axiosInstance.get(url + '/console/article', {
      params
    })

    return response.data
  },

  getSettings: async function getSettings (baseUrl) {
    const url = baseUrl || ''

    const response = await axiosInstance.get(url + '/admin/admin/settings')

    return response.data
  },

  setSettings: async function setSettings (baseUrl, params) {
    const url = baseUrl || ''

    const response = await axiosInstance.post(url + '/admin/admin/settings', params)

    return response.data
  }
})

export default {
  install: (app) => {
    app.config.globalProperties.$api = ApiService(axios.create({
      baseURL: BASE_API_URL
    }))
  }
}
